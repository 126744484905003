.toggle-button {
  height: 26px;
  background: transparent;
  border: none;
  cursor: pointer;
  min-height: 50px;
}

.toggle-button p {
  margin-right: 0px;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button_line {
  width: 30px;
  height: 4px;
  margin: 5px 2.5px 4px 2.5px;
  background: #4E4E4E;
  border-radius: 25px;
}


@media screen and (max-width: 500px) {
  .toggle-button_line {
    margin: 5px 0px 4px 1px;
  }
}