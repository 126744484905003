
/*____

CSS Rubrique Header

____*/

/*____Header-Background____*/

  .parallax-header {
    background-image: url("../../images/bg-header.jpg");
    height: 80vh;
    min-height: 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overlay-header {
    position: absolute;
    width: 100%;
    height: 80vh;
    background-color: #000000;
    opacity: .4;
  }

  /*____Header-Content____*/

  .content-header {
    position: absolute;
    width: 100%;
    height: 80vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  /*____Bloc Name____*/

  .content-header .content-bloc-name h1 {
    font-size: 50px;
    color: white;
    margin-left: 10px;
  }

  .content-header .content-bloc-name h1 .size-name {
    font-size: 60px;
    border-bottom: 2px solid #F59331;
    font-weight: 800;
  }

  .fadeIn-name,
  .fadeIn-subtitle {
    -webkit-animation-name: fadeIn-name;
    animation-name: fadeIn-name;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;

  }

  @-webkit-keyframes fadeIn-name {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIn-name {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  /*____LineOne - LineTwo____*/

  .content-header .content-bloc-name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 0px;
    -ms-flex-item-align: center;
        align-self: center;
  }

  .content-header .content-bloc-name .lineOne,
  .content-header .content-bloc-name .lineTwo {
    background-color: #F59331;
    width: 15px;
    margin: 0 5px;
    border-radius: 25px;
  }

  .content-header .content-bloc-name .lineOne {
    height: 50px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }

  .content-header .content-bloc-name .lineTwo {
    height: 40px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    margin-top: 10px;
  }

  .fadeInUp-lineOne {
    -webkit-animation-name: fadeInUp-lineOne;
    animation-name: fadeInUp-lineOne;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
  }

  @-webkit-keyframes fadeInUp-lineOne {
    0% {
      margin-top: 50px;
      width: 15px;
      height: 0px;
    }

    100% {
      margin-top: 0px;
      width: 15px;
      height: 50px;
    }
  }

  @keyframes fadeInUp-lineOne {
    0% {
      margin-top: 50px;
      width: 15px;
      height: 0px;
    }

    100% {
      margin-top: 0px;
      width: 15px;
      height: 50px;
    }
  }

  .fadeInUp-lineTwo {
    -webkit-animation-name: fadeInUp-lineTwo;
    animation-name: fadeInUp-lineTwo;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
  }

  @-webkit-keyframes fadeInUp-lineTwo {
    0% {
      margin-top: 50px;
      width: 15px;
      height: 0px;
    }

    100% {
      margin-top: 10px;
      width: 15px;
      height: 40px;
    }
  }

  @keyframes fadeInUp-lineTwo {
    0% {
      margin-top: 50px;
      width: 15px;
      height: 0px;
    }

    100% {
      margin-top: 10px;
      width: 15px;
      height: 40px;
    }
  }

  /*____Bloc Subtitle____*/

  .content-header .content-bloc-subtitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 2.5rem 0 0;
  }

  .content-header .align-bloc-subtitleOne {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .content-header .align-bloc-subtitleOne p,
  .content-header .align-bloc-subtitleThree p {
    font-size: 50px;
    font-weight: 600;
    color: white;
  }

  .content-header .line_subtitleOne {
    background-color: #F59331;
    width: 150px;
    height: 20px;
    border-radius: 25px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    margin-top: 9px;
    margin-right: 215px;
    margin-left: 30px;
  }

  .fadeInLeft-subtitle {
    -webkit-animation-name: fadeInLeft-subtitle;
    animation-name: fadeInLeft-subtitle;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
  }

  @-webkit-keyframes fadeInLeft-subtitle {
    0% {
      margin-right: 0px;
      width: 0;
      height: 20px;
    }

    100% {
      margin-right: 215px;
      width: 150px;
      height: 20px;
    }
  }

  @keyframes fadeInLeft-subtitle {
    0% {
      margin-right: 0px;
      width: 0;
      height: 20px;
    }

    100% {
      margin-right: 215px;
      width: 150px;
      height: 20px;
    }
  }

  .content-header .align-bloc-subtitleTwo p {
    font-size: 45px;
    font-weight: 600;
    padding-top: 25px;
    padding-bottom: 25px;
    color: white;
  }

  .content-header .align-bloc-subtitleThree .line_subtitleTwo {
    background-color: #F59331;
    width: 100px;
    height: 20px;
    border-radius: 25px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    margin-top: 5px;
    margin-left: 350px;
    margin-right: 30px;
  }

  .content-header .align-bloc-subtitleThree {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .fadeInRight-subtitle {
    -webkit-animation-name: fadeInRight-subtitle;
    animation-name: fadeInRight-subtitle;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
  }

  @-webkit-keyframes fadeInRight-subtitle {
    0% {
      margin-right: 0px;
      width: 0;
      height: 20px;
    }

    100% {
      margin-right: 30px;
      width: 100px;
      height: 20px;
    }
  }

  @keyframes fadeInRight-subtitle {
    0% {
      margin-right: 0px;
      width: 0;
      height: 20px;
    }

    100% {
      margin-right: 30px;
      width: 100px;
      height: 20px;
    }
  }

  /*______________________
  
Media Queries
_________________________*/

  @media screen and (max-width: 800px) {
    .content-header .content-bloc-name .lineOne {
      height: 40px;
    }

    .content-header .content-bloc-name .lineTwo {
      height: 30px;
    }

    .content-header .content-bloc-name h1 {
      font-size: 40px;
      margin-right: 15px;
    }

    .content-header .content-bloc-name h1 .size-name {
      font-size: 50px;
    }

    .content-header .align-bloc-subtitleOne p,
    .content-header .align-bloc-subtitleThree p {
      font-size: 40px;
    }

    .content-header .line_subtitleOne {
      width: 125px;
      margin-right: 150px;
      margin-left: 15px;
    }

    .content-header .align-bloc-subtitleTwo p {
      font-size: 35px;
    }

    .content-header .align-bloc-subtitleThree .line_subtitleTwo {
      width: 75px;
      margin-right: 15px;
      margin-left: 300px;
    }

    @-webkit-keyframes fadeInUp-lineOne {
      0% {
        margin-top: 40px;
        width: 15px;
        height: 0px;
      }

      100% {
        margin-top: 0px;
        width: 15px;
        height: 40px;
      }
    }

    @keyframes fadeInUp-lineOne {
      0% {
        margin-top: 40px;
        width: 15px;
        height: 0px;
      }

      100% {
        margin-top: 0px;
        width: 15px;
        height: 40px;
      }
    }

    @-webkit-keyframes fadeInUp-lineTwo {
      0% {
        margin-top: 40px;
        width: 15px;
        height: 0px;
      }

      100% {
        margin-top: 10px;
        width: 15px;
        height: 30px;
      }
    }

    @keyframes fadeInUp-lineTwo {
      0% {
        margin-top: 40px;
        width: 15px;
        height: 0px;
      }

      100% {
        margin-top: 10px;
        width: 15px;
        height: 30px;
      }
    }

    @-webkit-keyframes fadeInLeft-subtitle {
      0% {
        margin-right: 1px;
        width: 0;
        height: 20px;
      }

      100% {
        margin-right: 150px;
        width: 125px;
        height: 20px;
      }
    }

    @keyframes fadeInLeft-subtitle {
      0% {
        margin-right: 1px;
        width: 0;
        height: 20px;
      }

      100% {
        margin-right: 150px;
        width: 125px;
        height: 20px;
      }
    }

    @-webkit-keyframes fadeInRight-subtitle {
      0% {
        margin-right: 10px;
        width: 0;
        height: 20px;
      }

      100% {
        margin-right: 15px;
        width: 75px;
        height: 20px;
      }
    }

    @keyframes fadeInRight-subtitle {
      0% {
        margin-right: 10px;
        width: 0;
        height: 20px;
      }

      100% {
        margin-right: 15px;
        width: 75px;
        height: 20px;
      }
    }
  }

  @media screen and (max-width: 600px) {

    .content-header .content-bloc-name .lineOne,
    .content-header .content-bloc-name .lineTwo {
      width: 12.5px;
      margin: 0 2.5px;
    }

    .content-header .content-bloc-name .lineTwo {
      margin-top: 10px;
    }

    .content-header .content-bloc-name h1 {
      font-size: 35px;
      margin-left: 5px;
    }

    .content-header .content-bloc-name h1 .size-name {
      font-size: 40px;
    }

    .content-header .align-bloc-subtitleOne p,
    .content-header .align-bloc-subtitleThree p {
      font-size: 30px;
    }

    .content-header .line_subtitleOne {
      width: 75px;
      height: 12.5px;
      margin-right: 125px;
      margin-top: 5px;
    }

    .content-header .align-bloc-subtitleTwo p {
      font-size: 30px;
    }

    .content-header .align-bloc-subtitleThree .line_subtitleTwo {
      margin-left: 180px;
      height: 12.5px;
      margin-top: 0px;
    }

    @-webkit-keyframes fadeInUp-lineOne {
      0% {
        margin-top: 40px;
        width: 12.5px;
        height: 0px;
      }

      100% {
        margin-top: 0px;
        width: 12.5px;
        height: 40px;
      }
    }

    @keyframes fadeInUp-lineOne {
      0% {
        margin-top: 40px;
        width: 12.5px;
        height: 0px;
      }

      100% {
        margin-top: 0px;
        width: 12.5px;
        height: 40px;
      }
    }

    @-webkit-keyframes fadeInUp-lineTwo {
      0% {
        margin-top: 40px;
        width: 12.5px;
        height: 0px;
      }

      100% {
        margin-top: 10px;
        width: 12.5px;
        height: 30px;
      }
    }

    @keyframes fadeInUp-lineTwo {
      0% {
        margin-top: 40px;
        width: 12.5px;
        height: 0px;
      }

      100% {
        margin-top: 10px;
        width: 12.5px;
        height: 30px;
      }
    }

    @-webkit-keyframes fadeInLeft-subtitle {
      0% {
        margin-right: 1px;
        width: 0;
        height: 12.5px;
      }

      100% {
        margin-right: 125px;
        width: 75px;
        height: 12.5px;
      }
    }

    @keyframes fadeInLeft-subtitle {
      0% {
        margin-right: 1px;
        width: 0;
        height: 12.5px;
      }

      100% {
        margin-right: 125px;
        width: 75px;
        height: 12.5px;
      }
    }

    @-webkit-keyframes fadeInRight-subtitle {
      0% {
        margin-right: 10px;
        width: 0;
        height: 12.5px;
      }

      100% {
        margin-right: 15px;
        width: 75px;
        height: 12.5px;
      }
    }

    @keyframes fadeInRight-subtitle {
      0% {
        margin-right: 10px;
        width: 0;
        height: 12.5px;
      }

      100% {
        margin-right: 15px;
        width: 75px;
        height: 12.5px;
      }
    }
  }

  @media screen and (max-width: 500px) {

    .content-header .content-bloc-name .lineOne,
    .content-header .content-bloc-name .lineTwo {
      display: none;
    }

    .content-header .content-bloc-name h1 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      font-size: 30px;
      margin-left: 0px;
      margin-right: 0px;
    }

    .content-header .content-bloc-name h1 .size-name {
      font-size: 35px;
    }

    .content-header .align-bloc-subtitleOne p,
    .content-header .align-bloc-subtitleThree p {
      font-size: 25px;
    }

    .content-header .line_subtitleOne {
      width: 50px;
      height: 10px;
      margin-right: 75px;
    }

    .content-header .align-bloc-subtitleTwo p {
      font-size: 25px;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .content-header .align-bloc-subtitleThree .line_subtitleTwo {
      margin-left: 80px;
      height: 10px;
      width: 25px;
    }

    @-webkit-keyframes fadeInLeft-subtitle {
      0% {
        margin-right: 0px;
        width: 0;
        height: 10px;
      }

      100% {
        margin-right: 75px;
        width: 50px;
        height: 10px;
      }
    }

    @keyframes fadeInLeft-subtitle {
      0% {
        margin-right: 0px;
        width: 0;
        height: 10px;
      }

      100% {
        margin-right: 75px;
        width: 50px;
        height: 10px;
      }
    }

    @-webkit-keyframes fadeInRight-subtitle {
      0% {
        margin-right: 10px;
        width: 0;
        height: 10px;
      }

      100% {
        margin-right: 15px;
        width: 25px;
        height: 10px;
      }
    }

    @keyframes fadeInRight-subtitle {
      0% {
        margin-right: 10px;
        width: 0;
        height: 10px;
      }

      100% {
        margin-right: 15px;
        width: 25px;
        height: 10px;
      }
    }
  }

  /*____________________

CSS Rubrique About

_____________________*/

  .bloc-info_about {
    max-width: 1000px;
    background-color: #fff;
    -webkit-box-shadow: 0px 1px 3px #1c1a19;
            box-shadow: 0px 1px 3px #1c1a19;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: .8rem;
    margin-top: 7.5rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .bloc-info-about__img:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-box-shadow: .5rem .5rem 3rem 1px rgba(0, 0, 0, .05);
            box-shadow: .5rem .5rem 3rem 1px rgba(0, 0, 0, .05);
    border-radius: .8rem;
  }

  .bloc-info-about__img {
    min-width: 45rem;
    max-width: 45rem;
    height: 40rem;
    margin: 0;
    position: relative;
  }

  .bloc-info-about__img img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
    border-radius: 0 0.8rem 0.8rem 0;
  }

  .btn-popup {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 60%;
    outline: 0;
    margin-bottom: 2.5rem;
    padding: 10px;
    border-radius: 20px;
    border: none;
    background: #F59331;
    color: white;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    -o-transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  }

  .btn-popup:hover {
    border: 1px solid #fff;
  }

  .content-about {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 320px;

  }

  .content-about h2 {
    font-size: 30px;
    margin-bottom: 2.5rem;
  }

  .span-bold {
    font-weight: 600;
  }

  .content-about .style-span-about {
    height: 3px;
    width: 21rem;
    background: #F59331;
    margin-bottom: 2.5rem;
  }

  .content-about_text {
    text-align: justify;
    width: 80%;
    font-size: 14px;
  }

  .content-about_text p>ol {
    margin-left: 1.5rem;
  }

  /* Pop up */

  .popup-overlay {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 999;
  }

  .popup_inner {
    position: relative;
    background: rgb(255, 255, 255);
    margin: auto;
    border: 1px solid rgb(187, 187, 187);
    padding: 5px;
    -webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
            box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
    width: 1050px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;

  }

  .content-popup_text {
    width: 90%;
    font-size: 14px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .content-popup_text ul>li {
    list-style-type: none;
  }

  .content-about_popup h2 {
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
  }

  .icone-croix {
    margin-right: 40px;
    position: absolute;
    right: 0;
    top: 20px;
    opacity: .5;
    cursor: pointer;
  }

  .icone-croix img {
    width: 40px;
  }

  .content-subtitle-popup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1.5rem;
  }

  .content-historique img {
    width: 250px;
    float: right;
  }

  .content-text {
    width: 65%;
    margin-left: 0;
  }

  .content-popup_text p {
    margin-bottom: 2rem;
  }

  .content-subtitle-popup h3 {
    font-size: 20px;
    margin-left: 2.5px;
  }

  .subtitle-lineOne-popup,
  .subtitle-lineTwo-popup {
    background-color: #F59331;
    width: 6px;
    margin: 0 2px;
    border-radius: 25px;
  }

  .subtitle-lineOne-popup {
    margin-top: -5px;
    height: 25px;
  }

  .subtitle-lineTwo-popup {
    height: 20px;
  }

  .lien-ville button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: none;
    margin-top: 1.5rem;
    padding: 10px;
    border-radius: 20px;
    background: #F59331;
    color: white;
    cursor: pointer;
    z-index: 1;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  /* Media Queries */

  @media screen and (max-width: 1068px) {
    .bloc--image_title button>p {
      width: 90%;
    }

    .content-historique img {
      width: 200px;
    }

    .bloc-info_about {
      max-width: 80rem;
    }

    .bloc-info-about__img {
      min-width: 30rem;
      max-width: 30rem;
    }

    .popup-overlay {
      z-index: 1001;
    }

    .popup_inner {
      margin-bottom: auto;
      width: 90%;
    }

    .btn-popup {
      min-width: 90%;
    }

    @media screen and (max-width: 868px) {
      .bloc-info_about {
        max-width: 70rem;
      }

      .btn-popup {
        width: 50%;
      }

      .popup_inner {
        width: 700px;
      }
    }

    @media screen and (max-width: 768px) {
      .bloc-info_about {
        padding: 2.5rem;
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
            -ms-flex-direction: column-reverse !important;
                flex-direction: column-reverse !important;
        border-radius: 0rem;
      }

      .content-historique img {
        width: 150px;
        float: right;
      }

      .bloc-info-about__img {
        min-width: 100%;
        max-width: 100%;
        margin-top: 2.5rem;
      }

      .bloc-info-about__img img,
      .bloc--image-about_overlay {
        border-radius: 0.8rem;
      }

      .content-about h2 {
        padding-top: 2rem;
        font-size: 24px;
      }

      .bloc-info-about__img img {
        width: 100%;
        height: 100%;
      }

      .bloc--image_title button>p {
        width: 60%;
      }

      .content-subtitle-popup h3 {
        font-size: 20px;
      }

      .content-popup_text p {
        font-size: 14px;
      }

      .content-popup_text ul>li {
        font-size: 14px;
      }
      .btn-popup {
        min-width: 50%;
      }
    }

    @media screen and (max-width: 500px) {
      .icone-croix {
        font-size: 30px;
        margin-top: 1rem;
        margin-right: 20px;
      }

      .content-about_popup h2 {
        text-align: center;
        padding-top: 0rem;
        font-size: 20px;
        width: 50%;
      }

      .content-about .style-span {
        height: 2px;
        width: 15rem;
      }

      .content-subtitle-popup h3 {
        font-size: 16px;
        margin-top: 0px;
      }

      .content-popup_text p {
        font-size: 12px;
      }

      .content-popup_text {
        width: 90%;
      }

      .content-historique img {
        display: none;
      }

      .content-text {
        width: 100%;
      }

      .subtitle-lineOne-popup {
        margin-top: -9px;
      }

      .subtitle-lineTwo-popup {
        margin-top: -4px;
      }

      .text-supp_responsive {
        display: none;
      }

      .content-popup_text ul>li {
        font-size: 12px;
      }
      .btn-popup {
        min-width: 80%;
      }
    }

    @media screen and (max-width: 470px) {
      .bloc-info_about {
        padding: 0;
        margin-top: 5rem;
      }

      .bloc-info-about__img {
        -webkit-transform: none;
            -ms-transform: none;
                transform: none;
        height: 30rem;
      }

      .bloc-info-about__img img,
      .bloc--image-about_overlay {
        border-radius: 0.8rem 0.8rem 0 0;
      }

      .bloc-info_about {
        border-radius: 0rem;
      }

      .bloc-info-about__img img,
      .bloc--image-about_overlay {
        border-radius: 0;
      }

      .bloc--image_title button>p {
        width: 80%;
      }
    }
  }

  @media screen and (max-width: 380px) {
    .text-supp_mobile {
      display: none;
    }
  }

  @media screen and (max-width: 325px) {
    .popup_inner {
      margin-top: 70px;
    }

    .text-supp_smMobile {
      display: none;
    }
  }

  /*____________________

CSS Rubrique Carousel

_____________________*/

  .container-body {
    position: relative;
    height: auto;
  }

  .bloc-carousel {
    max-width: 1000px;
    background-color: #fff;
    -webkit-box-shadow: 0px 1px 3px #1c1a19;
            box-shadow: 0px 1px 3px #1c1a19;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    border-radius: .8rem;
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }

  .bloc-carousel__img:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-box-shadow: .5rem .5rem 3rem 1px rgba(0, 0, 0, .05);
            box-shadow: .5rem .5rem 3rem 1px rgba(0, 0, 0, .05);
  }

  .bloc-carousel__img img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
    border-radius: 0 0 0.8rem 0.8rem;
  }

  .btn-activite {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 15%;
    font-size: 14px;
    margin-bottom: 4rem;
    outline: 0;
    font-weight: 700;
    padding: 10px;
    border-radius: 20px;
    border: #F59331 2px solid;
    background: none;
    color: white;
    cursor: pointer;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -webkit-transition: all 0.5s cubic-bezier(.25, .8, .25, 1);
    -o-transition: all 0.5s cubic-bezier(.25, .8, .25, 1);
    transition: all 0.5s cubic-bezier(.25, .8, .25, 1);
    z-index: 900;
  }

  .btn-activite:hover {
    background: #F59331;
  }

  .bloc-carousel__img {
    height: 35rem;
    margin: 0;
    position: relative;
  }

  .bloc-carousel__img img {
    width: 100%;
    height: 35rem;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
    -webkit-filter: blur(1px);
    filter: blur(1px);
    z-index: 10;
  }

  .content-carousel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .content-carousel h2 {
    font-size: 30px;
    margin-bottom: 2.5rem;
    margin-right: .5rem;
    margin-left: .5rem;
  }

  .content-carousel_text {
    display: block;
    text-align: center;
    width: 40%;
    font-size: 14px;
    margin-bottom: 2.5rem;
  }

  .overlay-carousel {
    position: absolute;
    width: 100%;
    height: 35rem;
    background-color: #696969;
    opacity: .5;
    z-index: 1;
    border-radius: 0 0 0.8rem 0.8rem;
  }

  .content-img-carousel {
    margin-bottom: 15rem;
    width: 30%;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    font-size: 2rem;
    z-index: 900;
  }

  .content-title-carousel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2.5rem;
  }

  .title-lineOne-carousel,
  .title-lineTwo-carousel {
    background-color: #F59331;
    width: 7.5px;
    margin: 0 3px;
    border-radius: 25px;
  }

  .title-lineOne-carousel {
    margin-top: 1px;
    height: 27.5px;
  }

  .title-lineTwo-carousel {
    margin-top: 6px;
    height: 22.5px;
  }

  @media screen and (max-width: 1068px) {
    .bloc-carousel {
      max-width: 80rem;
    }

    .content-img-carousel {
      width: 50%;
    }
  }

  @media screen and (max-width: 868px) {
    .bloc-carousel {
      max-width: 70rem;
    }

    .btn-activite {
      width: 20%;
    }
  }

  @media screen and (max-width: 500px) {
    .content-carousel h2 {
      font-size: 24px;
    }

    .btn-activite {
      width: 35%;
    }

    .title-lineOne-carousel {
      margin-top: -2.5px;
      height: 25px;
    }

    .title-lineTwo-carousel {
      margin-top: 2.5px;
      height: 20px;
    }

    .title-lineOne-carousel,
    .title-lineTwo-carousel {
      width: 6px;
    }

    .content-carousel_text {
      width: 90%;
    }

    .carousel .control-next.control-arrow {
      margin-right: 2.5rem;
    }

    .carousel .control-prev.control-arrow {
      margin-left: 2.5rem;
    }

    .overlay-carousel {
      border-radius: 0;
    }

    .bloc-carousel__img img {
      border-radius: 0;
    }

    .bloc-carousel {
      border-radius: 0;
    }
  }

  /* --- CSS modif npm slide --- */

  .carousel .slider.animated {
    height: 35rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .carousel .control-prev.control-arrow {
    left: 0;
    margin-left: 7.5rem;
  }

  .carousel .control-next.control-arrow {
    right: 0;
    margin-right: 7.5rem;
  }

  .carousel .slider-wrapper {
    border-radius: 0 0 0.8rem 0.8rem;
  }

  .carousel.carousel-slider .control-arrow:hover {
    background: none !important;
  }

  @media screen and (max-width: 500px) {
    .carousel .control-next.control-arrow {
      margin-right: 2.5rem;
    }

    .carousel .control-prev.control-arrow {
      margin-left: 2.5rem;
    }

    .content-img-carousel {
      width: 80%;
    }

    .content-img-carousel h2 {
      font-size: 20px;
    }

    .content-img-carousel p {
      font-size: 16px;
    }

    .carousel .slider-wrapper {
      border-radius: 0;
    }
  }

  /*____________________

CSS Rubrique Adhésion

_____________________*/

  .bloc-adhesion {
    position: relative;
    max-width: 1000px;
    height: 50rem;
    background-color: #fff;
    -webkit-box-shadow: 0px 1px 3px #1c1a19;
            box-shadow: 0px 1px 3px #1c1a19;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: .8rem;
    margin-top: 7.5rem;
    background-image: url("../../images/img4.jpg");
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 2.5rem;
  }

  .overlay-adhesion {
    position: absolute;
    top: 0px;
    width: 100%;
    max-width: 1000px;
    height: 50rem;
    background-color: #696969;
    opacity: .5;
    z-index: 1;
    border-radius: .8rem;
  }

  .bloc-adhesion__img:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-box-shadow: .5rem .5rem 3rem 1px rgba(0, 0, 0, .05);
            box-shadow: .5rem .5rem 3rem 1px rgba(0, 0, 0, .05);
    border-radius: .8rem;
  }

  .bloc-adhesion__img img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
    border-radius: 0 0.8rem 0.8rem 0;
  }

  .btn-info {
    width: 25%;
    padding: 10px 20px;
    outline: 0;
    border-radius: 20px;
    border: none;
    background: #F59331;
    color: white;
    cursor: pointer;
    font-size: 16px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -webkit-transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    -o-transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    text-align: center;
  }

  .btn-info:hover {
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .bloc-adhesion__img {
    min-width: 45rem;
    max-width: 45rem;
    height: 40rem;
    margin: 0;
    position: relative;
  }

  .bloc-adhesion__img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
    border-radius: 0 0.8rem 0.8rem 0;
  }

  .content-adhesion {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background: white;
    padding: 2.5rem;
    width: 60%;
    -webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
            box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
    z-index: 1000;
  }

  .content-adhesion h2 {
    font-size: 30px;
    margin-right: .5rem;
    margin-left: .5rem;
  }

  .content-paragraph {
    margin: 1.5rem 2.5rem 2.5rem;
    width: 50%;
  }

  .content-adhesion_text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    text-align: center;
    font-size: 16px;
    margin-bottom: 1.5rem;
  }

  .content-title-adhesion {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 2.5rem;
  }

  .title-lineOne-adhesion,
  .title-lineTwo-adhesion {
    background-color: #F59331;
    width: 7.5px;
    margin: 0 3px;
    border-radius: 25px;
  }

  .title-lineOne-adhesion {
    margin-top: 0;
    height: 27.5px;
  }

  .title-lineTwo-adhesion {
    margin-top: 5px;
    height: 22.5px;
  }

  @media screen and (max-width: 1100px) {
    .bloc-adhesion {
      max-width: 80rem;
    }

    .overlay-adhesion {
      max-width: 80rem;
    }

    .content-paragraph {
      margin: 1.5rem;
      width: 100%;
    }

    .btn-info {
      min-width: 30%;
    }
  }

  @media screen and (max-width: 800px) {
    .bloc-adhesion {
      max-width: 70rem;
    }

    .overlay-adhesion {
      max-width: 70rem;
    }

    .content-adhesion {
      width: 72.5%;
    }

    .content-paragraph {
      margin: 1.5rem .5rem;
    }
  }

  @media screen and (max-width: 600px) {
    .bloc-adhesion {
      border-radius: 0;
    }

    .overlay-adhesion {
      border-radius: 0;
    }

    .bloc-adhesion__img:before {
      border-radius: 0;
    }

    .bloc-adhesion__img img {
      border-radius: 0;
    }
    .btn-info {
      min-width: 40%;
    }
  }

  @media screen and (max-width: 500px) {
    .content-adhesion h2 {
      font-size: 24px;
    }

    .title-lineOne-adhesion {
      margin-top: -1px;
      height: 25px;
    }

    .title-lineTwo-adhesion {
      margin-top: 4px;
      height: 20px;
    }

    .title-lineOne-adhesion,
    .title-lineTwo-adhesion {
      width: 6px;
    }

    .content-adhesion_text {
      font-size: 14px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-left: 1px;
      margin-right: 1px;
    }

    .bloc-adhesion {
      border-radius: 0;
    }

    .content-adhesion {
      width: 90%;
      padding: 1.5rem;
    }

    .content-paragraph {
      margin: .5rem;
    }
    .btn-info {
      width: 45%;
    }
  }

  /*____________________

CSS Rubrique Footer

_____________________*/

  .container-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 7.5rem;
    height: 100px;
    width: 100%;
    background: #A85D11;
    opacity: .4;
  }

  .content-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 70%;
    color: #fff;
    font-weight: 700;
  }

  .text-footer {
    text-align: center;
    font-size: 20px;
  }

  .create-by {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 30px;
    color: white;
    width: 100%;
    background: #A85D11;
    opacity: .6;
    font-size: 14px;
  }

  .create-by p {
    margin: 0 2.5rem;
  }

  @media screen and (max-width: 1068px) {
    .content-footer {
      width: 90%;
    }

    .text-footer {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 800px) {
    .create-by {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      height: 60px;
      padding: 5px;
    }
  }

  @media screen and (max-width: 500px) {
    .container-footer {
      height: auto;
    }

    .content-footer {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }

    .text-footer {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .create-by {
      height: auto;
      padding: 10px;
    }

    .create-by p {
      text-align: center;
      padding: 5px;
    }
  }

  @media screen and (max-width: 500px) {

    .animate__fadeInLeftBig,
    .animate__fadeInRightBig {
      -webkit-animation-name: none !important;
      animation-name: none !important;
    }
  }