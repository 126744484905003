.container-errorPage {
  height: 100vh;
}

.content-errorPage {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.content-errorPage h1 {
  font-size: 80px;
  margin-bottom: 2rem;
}

.content-errorPage h2 {
  font-size: 40px;
  margin-bottom: 3rem;
}

.btn-errorPage {
  outline: none;
  border: none;
  background: #F59331;
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  margin-top: 1.5rem;
  font-size: 18px;
  border: none;
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.24);
          box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  -webkit-animation: pulse 3s ease infinite;
  animation: pulse 3s ease infinite;
}

.btn-errorPage a {
  list-style-type: none;
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 800px) {
  .content-errorPage h1 {
    font-size: 40px;
    margin-bottom: 1rem;
    text-align: center;
  }

  .content-errorPage h2 {
    font-size: 20px;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .btn-errorPage {
    font-size: inherit;
  }
}