.parallax-activite {
  background: url("../../images/header-activite.jpg") no-repeat center center;
  background-size: cover;
  height: 60vh;
  max-height: 60%;
}

.overlay-activite {
  position: absolute;
  width: 100%;
  height: 60vh;
  background-color: #333;
  opacity: .4;
}

/* Bloc Content activites */

.content-activites {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.content-activites h1 {
  opacity: 0.7;
  font-size: 40px;
  margin-bottom: 1rem;
  color: black;
  text-align: center;
}

.content-activites .style-div,
.content-activites .style-sub-div {
  height: 4px;
  background: #F59331;
}

.content-activites .style-div {
  width: 14rem;
  margin-bottom: 1rem;
}

.content-activites .style-sub-div {
  width: 10rem;
  margin-bottom: 2.5rem;
}

.content-activites_text {
  display: block;
  text-align: center;
  width: 27.5%;
  font-size: 18px;
  margin-bottom: 2.5rem;
}


/* Card hover activite */

.body-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100vh;
}

.container-card {
  position: relative;
  width: 1000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 20px;
}

.container-card .card {
  position: relative;
  height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1000px;
  margin: 30px 0;
  cursor: pointer;
}

.container-card .card-evenement {
  height: 600px;
}

.container-card .card {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  -o-transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.container-card .card:hover,
.container-card .card-visible {
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.container-card .card {
  background: #F7B97B;
}

.container-card .card:nth-child(2n) {
  background: #FFA64D;
}

.container-card .card .card-title {
  position: absolute;
  font-size: 40px;
  right: 0;
  width: 50%;
  height: 100%;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: .45;
  -webkit-transition: ease-in .5s;
  -o-transition: ease-in .5s;
  transition: ease-in .5s;
}

.container-card .card .card-title h3 {
  text-align: center;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.container-card .card-visible .card-title {
  display: none;
}

.container-card .card:nth-child(2n) .card-title {
  left: 0;
  right: inherit;
  text-align: center;
}

.container-card .card .imgbox {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background: #333;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  -webkit-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}

.container-card .card .imgbox {
  left: 0;
}

.container-card .card:nth-child(2n) .imgbox {
  left: inherit;
  right: 0;
}

.container-card .card .imgbox img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.container-card .card:hover .imgbox,
.container-card .card-visible .imgbox {
  width: 300px;
  height: 300px;
  left: -75px;
  top: calc(50% - 150px);
  -webkit-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  border-radius: 25px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.container-card .card:hover:nth-child(2n) .imgbox,
.container-card .card-visible:nth-child(2n) .imgbox {
  width: 300px;
  height: 300px;
  left: inherit;
  right: -75px;
  top: calc(50% - 150px);
  -webkit-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  border-radius: 25px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card .content {
  position: relative;
  visibility: hidden;
  opacity: 0;
}

.card:hover .card-title {
  display: none;
}

.card:hover .content,
.card-visible .content {
  width: 77.5%;
  min-width: 450px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-animation-name: fadeInContent;
  animation-name: fadeInContent;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  margin: 0;
  padding-top: 25px;
  position: absolute;
  right: 0;
  visibility: visible;
  opacity: 1;
}

@-webkit-keyframes fadeInContent {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInContent {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.card:hover .content-evenement,
.card-visible .content-evenement {
  width: 75%;
}

.card:nth-child(2n) .content {
  position: absolute;
  right: inherit;
}

.card .content h3 {
  font-size: 40px;
  color: white;
  text-align: center;
}

.content-text-activite,
.content-text-evenement {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

.line-separation-text {
  height: 75%;
  width: 2px;
  background: white;
}

.content-descriptionOne,
.content-descriptionTwo {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.content-descriptionOne .style-text,
.content-descriptionTwo .style-text {
  font-size: 20px;
  margin: .5rem 0;
  padding: 10px;
  width: 90%;
}

.content-descriptionOne>.style-text>p:nth-child(1),
.content-descriptionTwo>.style-text>p:nth-child(1) {
  margin-bottom: 1rem;
}

#adhesion>div.content>div>div.content-descriptionOne>div {
  padding: 10px 10px 10px 30px;
}

.react-calendar {
  width: 550px !important;
  margin-bottom: 1rem;
  min-height: 250px;
}

@media (max-width: 1100px) {
  .parallax-activite {
    height: 40vh;
    max-height: 40%;
  }
  
  .overlay-activite {
    height: 40vh;
  }  
  .content-activites h1 {
    font-size: 30px;
  }
  .content-activites_text {
    width: 50%;
  }

  .container-card {
    -ms-flex-pack: distribute;
        justify-content: space-around;
    width: inherit;
  }

  .container-card .card {
    height: 500px;
    width: 450px;
  }

  .container-card .card .imgbox {
    height: 250px;
    width: 100%;
  }

  .container-card .card .card-title {
    width: 100%;
    height: 250px;
    margin-top: 250px;
  }

  .container-card .card:hover .imgbox,
  .container-card .card-visible .imgbox {
    width: 100%;
    height: 200px;
    left: 0px;
    top: inherit;
    border-radius: 0px;
  }

  .container-card .card:hover:nth-child(2n) .imgbox,
  .container-card .card-visible:nth-child(2n) .imgbox {
    width: 100%;
    height: 200px;
    left: inherit;
    right: 0px;
    top: inherit;
    border-radius: 0px;
  }

  .container-card .card:hover,
  .container-card .card-visible {
    height: 800px;
    position: inherit;
    right: inherit;
  }

  .card:hover .content,
  .card-visible .content {
    width: 100%;
    min-width: 0;
    padding-top: 220px;
  }

  .card .content h3 {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
  }

  .content-descriptionOne,
  .content-descriptionTwo {
    width: 100%;
  }

  .line-separation-text {
    height: 1px;
    width: 75%;
    margin: 1.5rem;
  }

  #adultes>div.content>div>div.line-separation-text {
    margin: .5rem;
  }

  .content-text-activite,
  .content-text-evenement {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .content-text-activite {
    min-height: 405px;
    padding-bottom: 2.5rem;
  }
  .content-text-evenement {
    min-height: 226px;
    
  }

  .container-card .card {
    -webkit-transition: all 1.5s cubic-bezier(.25, .8, .25, 1);
    -o-transition: all 1.5s cubic-bezier(.25, .8, .25, 1);
    transition: all 1.5s cubic-bezier(.25, .8, .25, 1);
  }

  #adultes>div.content>h3 {
    margin-bottom: 2rem;
  }

  #adhesion>div.content>div>div.content-descriptionOne>div {
    padding: 10px;
  }


  .react-calendar {
    margin-bottom: 0rem;
    margin-top: 1.5rem;
  }

  .container-card .card-evenement {
    height: 500px;
    width: 900px;
  }

  .container-card .card-evenement:hover,
  .container-card .card-evenement-visible {
    height: 825px;
  }
}

@media (max-width: 800px) {
  .content-activites_text {
    width: 50%;
  }

  .container-card .card-evenement:hover,
  .container-card .card-evenement-visible {
    height: 875px;
  }

  .container-card .card-evenement {
    width: 100%;
  }
}

@media (max-width: 650px) {

  .container-card .card-evenement:hover,
  .container-card .card-evenement-visible {
    height: 950px;
  }

  .react-calendar {
    width: 500px !important;
  }

  .content-activites h1 {
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  .content-activites_text {
    width: 80%;
  }

  .content-descriptionOne .style-text,
  .content-descriptionTwo .style-text {
    font-size: 16px;
  }

  .container-card .card {
    max-width: 360px;
  }

  .container-card {
    margin: 0 20px;
  }

  .react-calendar {
    width: 300px !important;
  }
  .card .content h3 {
    margin-bottom: 1.5rem;
    font-size: 30px;
  }
  .container-card .card:hover, 
  .container-card .card-visible {
    height: 675px;
  }
  .container-card .card-evenement:hover,
  .container-card .card-evenement-visible {
    height: 900px;
  }
  .content-text-evenement {
    margin-top: 1.5rem;
  }
  .content-text-activite {
    min-height: 291px;
  }
  #adultes>div.content>h3 {
    margin-bottom: 2rem;
  }
}

@media (max-width: 340px) {
  .container-card .card {
    width: 340px;
  }

  .container-card .card-evenement:hover, 
  .container-card .card-evenement-visible {
    height: 950px!important;
  }

  .container-card .card:hover, 
  .container-card .card-visible {
    height: 725px;
  }
}

@media (max-width: 320px) {
  .container-card .card {
    width: 320px;
  }
}





/* Calendar */
.container-calendar {
  padding-top: 1.5rem;
  padding-bottom: 4.5rem;
  width: 800px;
}
.content-calendar {
  width: 700px;
}

.fc a {
  color: black;
}
.fc .fc-view-harness {
  margin: 1px;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin: 1.5rem 0!important;
}
.fc .fc-toolbar-title {
  font-size: 2.75em!important;
}
.fc .fc-button {
  font-size: 1.5em!important;
}
.fc .fc-button-primary {
  background-color: #F59331!important;
  background-color: var(--fc-button-bg-color, #F59331)!important;
  border-color: #F59331!important;
  border-color: var(--fc-button-border-color, #F59331)!important;
}
.fc .fc-col-header-cell-cushion {
  font-size: 14px;
}
.fc-direction-ltr {
  background: white;
  padding: 0 5rem 1.5rem;
}
@media (max-width: 700px) {
  .container-calendar {
    width: 95%;
  }
  .content-calendar {
    width: inherit;
  }
}
@media (max-width: 500px) {
  .container-calendar {
    width: 100%;
  }
  .fc-direction-ltr {
    padding: 0;
  }
  .fc .fc-toolbar-title {
    font-size: 1.75em!important;
  }
  .fc .fc-button {
    font-size: 1em!important;
  }
  .fc .fc-col-header-cell-cushion {
    font-size: 10px;
  }
}
@media (max-width: 320px){
  .fc-direction-ltr {
      height: 325px;
  }
}