.scroll-to-top {
  position: fixed;
  bottom: 15px;
  z-index: 1;
  right: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  -webkit-animation: apparition .5s;
          animation: apparition .5s;
}

.scroll-to-top:hover {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

@-webkit-keyframes apparition {
  from {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes apparition {
  from {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.scroll-to-top img {
  background: lightgray;
  border-radius: 5px;
  width: 40px;
  padding: 5px;
}

@media screen and (max-width: 500px) {
  .scroll-to-top {
    right: 20px;
  }
}