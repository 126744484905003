.toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1100;
  position: fixed;
  width: 100%;
  background: white;
  -webkit-box-shadow: 0px 2px 5px #1c1a19;
          box-shadow: 0px 2px 5px #1c1a19;
  height: 60px;
}

.toolbar h1 {
  margin-left: 10rem;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 35px;
  cursor: pointer;
}

.toolbar h1 a {
  color: #4E4E4E;
}

.toolbar__toggle-button {
  position: fixed;
  z-index: 1100;
}

.toolbar_navigation {
  height: 100%;
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
  margin-right: 7.5rem;
  padding-top: 5px;
}

.open_sidedrawer {
  opacity: 0;
}

/*___Media Queries___*/

@media screen and (max-width: 500px) {
  .toolbar h1 {
    margin-left: 5rem;
    font-size: 25px;
  }
}