.parallax-mentions {
  background-image: url("../../images/bg-mention.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  min-height: 60%;
}

.container-mentionPage {
  font-size: 24px;
}

.container-mentionPage h1 {
  text-align: center;
  margin: 5rem;
}

.container-mentionPage .span-inline {
  color: #F59331;
}
.content-mentionPage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 2.5rem;
}
.content-mentionPage h2 {
  margin: 0 0 1.5rem;
}
.content-mentionPage .content-text {
  margin: 2.5rem;
  width: 75%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}
.content-mentionPage .content-presentationSite p{
  margin: 0 0 1rem;
}
.content-mentionPage .content-presentationSite ul{
  list-style: none;
}

@media screen and (max-width: 768px) {
  .content-mentionPage .content-text {
    width: 90%;
  }
}
@media screen and (max-width: 500px) {
  .container-mentionPage h1 {
    margin: 2.5rem 0;
    font-size: 35px;
  }
  .content-mentionPage h2 {
    font-size: 30px;
  }
}