/*_______________

Instructions all page

______________*/

html {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

*,
*:before,
*::after {
  margin: 0 auto;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

img {
  width: 100%;
}

body {
  overflow-x: hidden;
  background: -webkit-gradient(linear, left top, left bottom, from(#FEFEFE), color-stop(90%), to(#DCDCDC));
  background-attachment: fixed;
  background: -o-linear-gradient(#FEFEFE, 90%, #DCDCDC);
  background: linear-gradient(#FEFEFE, 90%, #DCDCDC);
  color: #4E4E4E;

}

a {
  color: white;
  text-decoration: none;
}


.animate_modal {
  -webkit-animation: zoomInModal 1s linear forwards;
          animation: zoomInModal 1s linear forwards;
  margin: 0;
}

@-webkit-keyframes zoomInModal {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes zoomInModal {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.animate_modal-gallery {
  -webkit-animation: zoomInModalGallery 1s linear forwards;
          animation: zoomInModalGallery 1s linear forwards;
  margin: 0;
}

@-webkit-keyframes zoomInModalGallery {
  0% {
      opacity: 0;
      -webkit-transform: scale(.5);
            transform: scale(.5);
}

  50% {
      opacity: 1;
      -webkit-transform: scale(1);
            transform: scale(1);
}
}

@keyframes zoomInModalGallery {
  0% {
    opacity: 0;
    -webkit-transform: scale(.5);
            transform: scale(.5);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
