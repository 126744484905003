.parallax-gallery {
  background: url("../../images/header-gallery.jpg") no-repeat center center;
  background-size: cover;
  height: 60vh;
  max-height: 60%;
}

.overlay-gallery {
  position: absolute;
  width: 100%;
  height: 60vh;
  background-color: #333;
  opacity: .2;
}

/* GalerieBis */

/* Bloc Content Galerie */

.content-galerie {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.content-galerie h1 {
  opacity: 0.7;
  font-size: 40px;
  margin-bottom: 1rem;
  color: black;
  text-align: center;
}

.content-galerie .style-div,
.content-galerie .style-sub-div {
  height: 4px;
  background: #F59331;
}

.content-galerie .style-div {
  width: 14rem;
  margin-bottom: 1rem;
}

.content-galerie .style-sub-div {
  width: 10rem;
  margin-bottom: 2.5rem;
}

.content-galerie_text {
  display: block;
  text-align: center;
  width: 27.5%;
  font-size: 18px;
  margin-top: 1.5rem;
  margin-bottom: 4.5rem;
}

.container-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.content-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.apparition-liste {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 600;
  margin: 0;
}

.apparition-liste .arrowTag {
  width: 20px;
  height: 20px;
  margin-top: .5px;
  margin-left: .5rem;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  cursor: pointer;
}

.animation-rebond {
  -webkit-animation: rebond .5s ease infinite alternate;
  animation: rebond .5s ease infinite alternate;
}

@-webkit-keyframes rebond {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(2.5px);
            transform: translateX(2.5px);
  }
}

@keyframes rebond {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(2.5px);
            transform: translateX(2.5px);
  }
}

.animate__backInLeft {
  -webkit-animation-name: backInLeft;
  animation-name: backInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-transition: ease-in 1s;
  -o-transition: ease-in 1s;
  transition: ease-in 1s;
}

@-webkit-keyframes backInLeft {
  0% {
    -webkit-transform: translateX(-1000px) scale(0.7);
            transform: translateX(-1000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateX(0px) scale(0.7);
            transform: translateX(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes backInLeft {
  0% {
    -webkit-transform: translateX(-1000px) scale(0.7);
            transform: translateX(-1000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateX(0px) scale(0.7);
            transform: translateX(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.animation {
  -webkit-animation: zoomIn .5s linear forwards;
  animation: zoomIn .5s linear forwards;
  margin: 0;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.btn-list {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 18px;
  padding: 5px;
  margin: 0;
}

.btn-list>button {
  width: 100%;
  padding: 10px 10px;
  cursor: pointer;
  border: none;
}

.btn-list:hover button {
  background: #F59331;
  color: white;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}

.btn-list button:focus {
  background-color: #F59331;
  color: #fff;
  border-radius: 2.5px;
  outline: none;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

/* Galerie pas dynamique IE et Safari*/
.container {
  max-width: 1500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container>div>.content>img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.container>div>.content:hover>img {
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}

.content {
  margin: 0px 0px 5px 5px;
  width: 400px;
  height: 250px;
  overflow: hidden;
  cursor: pointer;
}

.overlay {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: -50%;
  left: 0;
  height: 49.5%;
  background: -o-linear-gradient(rgba(168, 96, 17, .9), #542F09);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(168, 96, 17, .9)), to(#542F09));
  background: linear-gradient(rgba(168, 96, 17, .9), #542F09);
  opacity: 0;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}

.overlay-text {
  color: white;
  text-align: center;
}

.overlay-text h2 {
  font-size: 20px;
}

.overlay-text p {
  margin-top: 1rem;
  font-size: 14px;
}

.content:hover .overlay {
  opacity: 1;
  top: -50%;
}

/*Galerie qui support CSS Grid*/

@supports (display: grid) {
  .animation {
    margin: initial;
  }

  .container {
    display: -ms-grid;
    display: grid;
    max-width: 1500px;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 225px;
    grid-auto-flow: dense;
    -ms-flex-wrap: inherit;
        flex-wrap: inherit;
    -webkit-box-pack: inherit;
        -ms-flex-pack: inherit;
            justify-content: inherit;
  }

  .content {
    width: 100%;
    height: 100%;
    margin: inherit;
  }

  .panorama {
    -ms-grid-column-span: 2;
    grid-column: span 2;
  }

  .portrait {
    -ms-grid-row-span: 2;
    grid-row: span 2;
  }

  .big {
    -ms-grid-column-span: 2;
    grid-column: span 2;
    -ms-grid-row-span: 2;
    grid-row: span 2;
  }
}


@media (max-width: 1100px) {
  .content-galerie_text {
    width: 50%;
  }
  .parallax-gallery {
    height: 40vh;
    max-height: 40%;
  }
  .overlay-gallery {
    height: 40vh;
  }
  .content-galerie h1 {
    font-size: 30px;
  } 
}

@media (max-width: 768px) {
  .content-galerie_text {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .content-galerie h1 {
    font-size: 24px;
  }

  .content-galerie_text {
    font-size: 14px;
    width: 85%;
  }

  .overlay-text h2 {
    font-size: 16px;
  }

  .overlay-text p {
    font-size: 12px;
    width: 90%;
  }

  .content-galerie .style-div,
  .content-galerie .style-sub-div {
    height: 3px;
  }
}

@media (max-width: 500px) {
  .container {
    margin: 2.5rem 5px;
    -ms-grid-columns: inherit;
    grid-template-columns: inherit;
    grid-auto-rows: 125px;
    grid-auto-flow: dense;
  }
}

@media (max-width: 415px) {
  .paysage {
    -ms-grid-column-span: 2;
    grid-column: span 2;
    -ms-grid-row-span: 2;
    grid-row: span 2;
  }

  .vertical {
    -ms-grid-column-span: 2;
    grid-column: span 2;
    -ms-grid-row-span: 4;
    grid-row: span 4;
  }
}


/* Lightbox - Modal Gallery */
.overlay-modal-gallery {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}

.modal-gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transition: 0.25s ease-out;
  -o-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1;
}

.full-img {
  height: 70%;
  width: auto;
}

.modal-gallery .content-text-img {
  color: white;
  font-size: 2rem;
  position: absolute;
  bottom: 2%;
  left: 50%;
  -webkit-transform: translate(-50%, -2%);
      -ms-transform: translate(-50%, -2%);
          transform: translate(-50%, -2%);
}

.modal-gallery .content-text-img .caption {
  text-align: center;
  margin: 1.5rem;
  font-size: 24px;
}

.modal-gallery .content-text-img p {
  text-align: center;
}

.icone-croix-thumbnail {
  margin-right: 150px;
  position: absolute;
  right: 0;
  top: 100px;
  opacity: 1;
  cursor: pointer;
  z-index: 1;
  width: 40px;
}

@media (max-width: 1400px) {
  .full-img {
    height: auto;
    max-width: 80%;
    max-height: 60%;
  }

  .modal-gallery .content-text-img {
    bottom: 2.5%;
    -webkit-transform: translate(-50%, -2.5%);
        -ms-transform: translate(-50%, -2.5%);
            transform: translate(-50%, -2.5%);
    width: 60%;
  }

  .icone-croix-thumbnail {
    margin-right: 100px;
    top: 15%;
  }
}

@media (max-width: 1100px) {
  .icone-croix-thumbnail {
    margin-right: 75px;
    top: 12.5%;
  }
}

@media (max-width: 800px) {
  .modal-gallery .content-text-img {
            bottom: 5%;
            -webkit-transform: translate(-50%, -5%);
            -ms-transform: translate(-50%, -5%);
            transform: translate(-50%, -5%);
            width: 60%;
  }
}
.box-moreGallery {
  display: flex;
  align-items: center;
  background: #F59331;
  width: 50px;
  border-radius: 10px;
  cursor: pointer;
  transition: .5s;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border: none;
  outline: none;
}
button:disabled {
  background: grey!important;
  opacity: .5!important;
  cursor: not-allowed;
}
.box-moreGallery:hover {
  transform: scale(1.1);
}
.moreGallery {
  width: 40px;
}