.side-drawer {
  height: 100%;
  background: white;
  -webkit-box-shadow: 1px 0px 7px rgba(0, 0, 0, .5);
          box-shadow: 1px 0px 7px rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  max-width: 500px;
  z-index: 1200;
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.5s ease-out;
  transition: -webkit-transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}

.side-drawer.open {
  -webkit-transform: translateX(0%);
      -ms-transform: translateX(0%);
          transform: translateX(0%);
}

.side-drawer .logo {
  background: #BC3434;
  width: 100%;
  color: white;
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  text-decoration: none;
}

.side-drawer ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: #E6E6E6;
  text-align: center;
  font-size: 20px;
  padding: 80px 0;
  line-height: normal;
  -webkit-transition: 1.5s;
  -o-transition: 1.5s;
  transition: 1.5s;
  z-index: 1;
  height: 100%;
}

.side-drawer li {
  width: 100%;
}

.side-drawer .menu-items {
  text-transform: uppercase;
  text-decoration: none;
  padding: 20px;
  font-weight: 700;
  display: block;
  color: #A85D11;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

.side-drawer .menu-items:hover,
.side-drawer .menu-items:active {
  background: #F7B97B;
  color: white;
}

.close-menu {
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 1.5rem;
  margin-right: 5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  opacity: 0;
}

.close-menu-open {
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 1.5rem;
  margin-right: 5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.close-menu-open:focus {
  outline: none;
}

.close-menu-style {
  width: 30px;
  height: 30px;
}

.fa-angle-left {
  height: 25px;
  color: #FFF;
}

.icon-reseaux {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.icon-reseaux img {
  width: 29px;
  margin: 14.5px;
  cursor: pointer;
}

.icon-reseaux img:hover {
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}

@media screen and (max-width: 801px) {
  .side-drawer {
    width: 100%;
    max-width: 400px;
    -webkit-box-shadow: none;
            box-shadow: none;
  }

  .side-drawer .logo {
    height: 100px;
    font-size: 20px;
  }

  .fa-angle-left {
    height: 20px;
  }

  .close-menu-style {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 600px) {
  .side-drawer {
    max-width: 600px;
  }
}